import React from "react"
import Page from "../components/page";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag"

const PrivacyPage = (props) => {
    const pageTitle="Privacy Policy";

    return (
        <Page location={props.location.href} pageTitle={pageTitle}>
        <h1>{pageTitle}</h1>
        <p>Effective Date: March 1st 2024</p>
        <p>Welcome to cbbratings.com. This Privacy Policy outlines our practices regarding the use of cookies for advertising purposes and applies to all visitors, users, and others who access the website ("Users").</p>
        
        <h2>1. Cookies and Advertising</h2>
        <p>
            We do not collect personal information from Users directly. However, we use third-party advertising partners to display ads on our site, which use cookies to serve ads based on a User's prior visits to our website or other websites.
            Cookies are small data files stored on your device's hard drive or in device memory that help us improve our services and your experience, see which areas and features of our services are popular, and count visits.
        </p>
        
        <h2>2. Use of Cookies</h2>
        <p>
            The cookies used for advertising purposes are designed to display relevant advertisements to you, improve reporting on campaign performance, and avoid showing ads you have already seen.
            Cookies do not include personal information about you but may track your internet usage for targeted advertising purposes.
        </p>

        <h2>3. Your Choices</h2>
        <p>
            Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our ads.
            For more information about cookies and how to disable them, you can visit <OutboundLink href="https://www.allaboutcookies.org/">allaboutcookies.org</OutboundLink>
        </p>
        
        <h2>4. Third-Party Advertisers</h2>
        <p>
            The third-party advertisers may use cookies to collect information about your activities on our website and other websites to provide you targeted advertising based upon your interests.
            This Privacy Policy does not apply to the cookies used by third-party advertisers and we are not responsible for their privacy policies and practices. Please visit the respective privacy policies of these third-party advertisers to learn more about their use of cookies.
        </p>

        <h2>5. Changes to This Privacy Policy</h2>
        <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top.
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>
    
        <h2>6. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please <Link to="/contactus">contact us</Link>.</p>
</Page>);
};
export default PrivacyPage;